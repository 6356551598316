import React from 'react';
import { Link, graphql, navigate } from 'gatsby';
import Img from 'gatsby-image';
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
} from 'semantic-ui-react';

import Layout from '../components/layout';
import { SEO } from '../components/seo';

import withDrupalOauthConsumer from '../components/drupal-oauth/withDrupalOauthConsumer';
import DrupalLoginButton from '../components/drupal-oauth/DrupalLoginButton';

import './index.less';
import HeroImage from '../assets/images/hero-img.svg';
import TwitterCardImage from '../assets/images/hn-homepage-card.png';

const SectionContainer = ({ children }) => (
  <Container className="section-container">{children}</Container>
);

const IndexPage = ({ data, userAuthenticated }) => {
  const tutorialsByPath = {};
  data.allNodeTutorial.edges.map((node) => {
    tutorialsByPath[node.node.path.alias] = node.node;
  });

  return (
    <main>
      <SEO
        title="Solve Hard Problems Faster"
        description="Welcome to the newest training site from Osio Labs. Our guided learning paths will help you think, prototype, and solve in Node.js"
        keywords={[`node`, `node.js`, `training`, `education`, `tutorials`]}
        image={TwitterCardImage}
        meta={[
          { name: 'twitter:card', content: 'summary_large_image' },
          {
            name: 'twitter:image:alt',
            content:
              'Text says go further with heynode.com your trusted guide for node.js, both written and video tutorials. Illustration shows a man holding a bike and a woman reading a book at a bus stop next to a sign with node.js on it.',
          },
        ]}
      />
      <Grid centered columns={12} container>
        <Grid.Row className="intro">
          <Grid.Column computer={5} tablet={7} mobile={12}>
            <h1>
              Data Brokering with Node.js:{' '}
              <span className="red">
                Process data at the speed of technology
              </span>
            </h1>
            <p>Free instant access to our full course.</p>
            {userAuthenticated ? (
              <Button
                as={Link}
                to="#tutorial-list"
                icon
                labelPosition="left"
                primary
                size="huge"
              >
                <Icon name="right arrow" />
                Start learning now
              </Button>
            ) : (
              <DrupalLoginButton
                title={
                  <>
                    <Icon name="right arrow" />
                    Start learning now
                  </>
                }
                useRegistrationLink
                classes="ui icon primary huge left labeled button"
              />
            )}
          </Grid.Column>
          <Grid.Column
            computer={7}
            tablet={5}
            mobile={12}
            textAlign="center"
            verticalAlign="bottom"
          >
            <Image
              src={HeroImage}
              width="752px"
              heigh="464px"
              alt="People waiting at a bus stop in front of a sign that says Node.js"
            />
          </Grid.Column>
        </Grid.Row>

        {!userAuthenticated && (
          <>
            <Grid.Row className="what-learn">
              <Grid.Column computer={10} mobile={12}>
                <Header as="h2">What you'll learn</Header>
                <p>
                  In this full course from Hey Node, learn how to harness the
                  power of Node.js to process big data including:
                </p>
              </Grid.Column>
              <Grid.Column computer={5} tablet={6} mobile={12}>
                <ul>
                  <li>
                    How to install Node.js and manage a local development
                    environment
                  </li>
                  <li>
                    How to read, and write data via both the file system and
                    Node.js streams
                  </li>
                  <li>
                    The inner workings of Node's event loop, promises, and
                    asynchronous APIs
                  </li>
                </ul>
              </Grid.Column>
              <Grid.Column computer={5} tablet={6} mobile={12}>
                <ul>
                  <li>
                    Using npm to manage 3rd party libraries, and keeping custom
                    code organized with modules
                  </li>
                  <li>Setting up an API proxy server using Express</li>
                  <li>
                    Common design patterns for applications that process large
                    amounts of data with Node.js
                  </li>
                </ul>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="what-build">
              <Grid.Column computer={10} mobile={12}>
                <Header as="h2">What you'll build</Header>

                <p>
                  When it comes to learning Node.js, there's no substitute for
                  tutorials that build real, production-ready, applications.
                  Some of the things you'll build include:
                </p>
              </Grid.Column>
              <Grid.Column computer={5} mobile={12} className="example-code">
                <Img
                  fluid={data.HomePageCode.childImageSharp.fluid}
                  alt="Screenshot VSCode with example code in it."
                />
              </Grid.Column>
              <Grid.Column computer={5} mobile={12}>
                <p>
                  <strong>Two different Node applications</strong> that use an
                  Extract, Transform, Load (ETL) pipeline to process data from
                  NASA's exoplanet API: one using the `fs` module, and the other
                  using streams
                </p>

                <p>
                  <strong>An Express-based API proxy server</strong> that allows
                  us to customize data from the Exoplanet API and return a
                  subset of the data customized for our needs, plus performance
                  optimizations like caching and data compression.
                </p>
              </Grid.Column>
            </Grid.Row>
          </>
        )}

        <Grid.Row className="tutorials">
          <Grid.Column computer={10} mobile={12}>
            <Header as="h2">Tutorials</Header>

            <p>
              This course contains approximately 12 hours of written and video
              content, code samples, and exercises covering:
            </p>

            <ul>
              <li>
                <a href="#etl">Processing Large Amounts of Data with Node.js</a>
              </li>
              <li>
                <a href="#modules">Node’s Module System</a>
              </li>
              <li>
                <a href="#async">
                  Write Asynchronous Node.js Code With Async, Await, and
                  Promises
                </a>
              </li>
              <li>
                <a href="#fs">Node.js for Reading and Writing Files</a>
              </li>
              <li>
                <a href="#streams">What Are Streams in Node.js?</a>
              </li>
              <li>
                <a href="#nvm">Install and Upgrade Node.js</a>
              </li>
              <li>
                <a href="#env">
                  Manage Application Configuration with Environment Variables in
                  Node.js
                </a>
              </li>
              <li>
                <a href="#proxy">
                  Set up API proxy middleware with Node.js Express
                </a>
              </li>
              <li>
                <a href="#express-authentication">
                  User Authentication in Node.js Applications
                </a>
              </li>
              <li>
                <a href="#event-loop">Explore Node's Event Loop</a>
              </li>
            </ul>

            <SectionContainer>
              <h2 id="etl">Processing Large Amounts of Data with Node.js</h2>
              <p>
                Learn to process a large amount of data with Node.js, (called
                data brokering), using the ETL (Extract, Transform, Load)
                pattern. With Node, you can share data between services without
                tightly coupling applications together or rewriting them. We
                often have data locked away in a legacy API, third party system,
                or somewhere else. When we need to access that data from a new
                frontend application, a GraphQL server, (or anywhere in our
                stack where we weren't accessing data before), Node.js is an
                excellent tool.
              </p>
              <p>
                In this Node tutorial series, learn about using the Node ETL
                pattern to retrieve, move, and process data anywhere.
              </p>
              <p>
                <strong>Tutorials:</strong>
              </p>
              <ul>
                <li>
                  <a href="/tutorial/what-data-brokering/">
                    Overview of Data Brokering With Node.js
                  </a>
                </li>
                <li>
                  <a href="/tutorial/etl-pipeline-what-are-we-building/">
                    Node.js ETL (Extract, Transform, Load) Pipeline: What Are We
                    Building?
                  </a>
                </li>
                <li>
                  <a href="/tutorial/what-etl-extract-transform-load-pipeline/">
                    What Is the Node.js ETL (Extract, Transform, Load) Pipeline?
                  </a>
                </li>
                <li>
                  <a href="/tutorial/extract-data-api-requests/">
                    ETL: Extract Data with Node.js
                  </a>
                </li>
                <li>
                  <a href="/tutorial/etl-transform-data">
                    ETL: Transform Data with Node.js
                  </a>
                </li>
                <li>
                  <a href="/tutorial/etl-load-data-destination/">
                    ETL: Load Data to Destination with Node.js
                  </a>
                </li>
              </ul>
            </SectionContainer>

            <SectionContainer>
              <h2 id="modules">Node’s Module System</h2>
              <p>
                A module is any file or directory in the node_modules directory
                that can be loaded by the Node.js <code>require()</code>{' '}
                function. As building blocks of code structure, Node.js modules
                allow developers to better structure, reuse, and share code.
                Node.js applications use Node package manager (npm) or yarn and
                a<em>package.json</em> file to keep track of the modules they
                depend on. Learn what npm is, how to create a{' '}
                <em>package.json</em> file, and how to install or update
                specific packages using npm. These sections are full of best
                practices for using npm for both beginners and experienced
                Node.js developers.
              </p>
              <p>
                <strong>Tutorials:</strong>
              </p>
              <ul>
                <li>
                  <a href="/tutorial/how-node-module-system-works/">
                    How the Node Module System Works
                  </a>
                </li>
                <li>
                  <a href="/tutorial/what-npm/">
                    Introduction to the Node Package Manager (NPM)
                  </a>
                </li>
                <li>
                  <a href="/tutorial/what-packagejson">
                    What Is package.json?
                  </a>
                </li>
                <li>
                  <a href="/tutorial/create-packagejson-file/">
                    Create a package.json File
                  </a>
                </li>
                <li>
                  <a href="/tutorial/what-package-lockjson/">
                    What Is package-lock.json?
                  </a>
                </li>
                <li>
                  <a href="/tutorial/install-npm-packages/">
                    How to Install NPM Packages
                  </a>
                </li>
                <li>
                  <a href="/tutorial/how-uninstall-npm-packages-nodejs-project/">
                    Uninstall Packages from a Project
                  </a>
                </li>
                <li>
                  <a href="/tutorial/how-use-semantic-versioning-npm/">
                    How to Use Semantic Versioning in NPM
                  </a>
                </li>
                <li>
                  <a href="/tutorial/how-update-node-dependency-npm/">
                    How to Update a Node Dependency with NPM
                  </a>
                </li>
                <li>
                  <a href="/tutorial/what-are-npm-scripts/">
                    What Are NPM Scripts?
                  </a>
                </li>
                <li>
                  <a href="/tutorial/restart-nodejs-application-upon-changing-file/">
                    Restart a Node.js Application upon Changing a File
                  </a>
                </li>
                <li>
                  <a href="/tutorial/organize-your-nodejs-code-modules/">
                    Organize Your Node.js Code into Modules
                  </a>
                </li>
              </ul>
            </SectionContainer>

            <SectionContainer>
              <h2 id="async">
                Write Asynchronous Node.js Code With Async, Await, and Promises
              </h2>
              <p>
                In this tutorial series, learn how to write clean, non-blocking
                Node.js code using <code>async</code>, <code>await</code>, and
                promises. Understanding asynchronous code is an important part
                of understanding how Node.js works.
              </p>
              <p>
                <strong>Tutorials:</strong>
              </p>
              <ul>
                <li>
                  <a href="/tutorial/what-are-promises/">
                    Understanding Promises in Node.js
                  </a>
                </li>
                <li>
                  <a href="/tutorial/wait-multiple-promises/">
                    Use Promise.all to Wait for Multiple Promises in Node.js
                  </a>
                </li>
                <li>
                  <a href="/tutorial/use-asyncawait-promises/">
                    Use JavaScript's Async/Await with Promises
                  </a>
                </li>
                <li>
                  <a href="/tutorial/how-make-api-requests-request-promise-nodejs/">
                    How to Make API Requests with Request-Promise in Node.js
                  </a>
                </li>
              </ul>
            </SectionContainer>

            <SectionContainer>
              <h2 id="fs">Node.js for Reading and Writing Files</h2>
              <p>
                Learn about the Node.js core fs module’s{' '}
                <code>fs.readFile</code> and
                <code>fs.writeFile</code>. Use Node.js to parse JSON data from
                files, and save a Node.js object to JSON.
              </p>
              <p>
                <strong>Tutorials:</strong>
              </p>
              <ul>
                <li>
                  <a href="/tutorial/what-fs-file-system-module/">
                    What is the fs (File System) Module?
                  </a>
                </li>
                <li>
                  <a href="/tutorial/readwrite-json-files-nodejs/">
                    Read/Write JSON Files with Node.js
                  </a>
                </li>
              </ul>
            </SectionContainer>

            <SectionContainer>
              <h2 id="streams">What Are Streams in Node.js?</h2>
              <p>
                Like Unix pipes, Node.js streams allow you to transfer large
                amounts of data in real on mobile devices or websites by
                connecting the data source and consumer. Node.js streams can
                transfer large amounts of data quickly and efficiently by
                breaking the data into chunks instead of reading it all into
                memory at once. In this Node.js tutorial, learn to use{' '}
                <code>fs.createReadStream</code>,
                <code>fs.createWriteStream</code>, and <code>pipe</code> to
                process large CSV and JSON files.
              </p>
              <p>
                <strong>Tutorials:</strong>
              </p>
              <ul>
                <li>
                  <a href="/tutorial/what-stream/">What Is a Node.js Stream?</a>
                </li>
                <li>
                  <a href="/tutorial/use-streams-extract-transform-and-load-csv-data/">
                    Use Streams to Extract, Transform, and Load CSV Data
                  </a>
                </li>
                <li>
                  <a href="/tutorial/stream-http-response-nodejs/">
                    Stream to an HTTP Response with Node.js
                  </a>
                </li>
              </ul>
            </SectionContainer>

            <SectionContainer>
              <h2 id="nvm">Install and Upgrade Node.js</h2>
              <p>
                Learn how to download and install Node.js, update to the latest
                version of Node, and manage multiple versions of Node.js on the
                same environment using nvm (the Node Version Manager). These
                tutorials also explain the Node.js version release cycle,
                security updates, and LTS releases.
              </p>
              <p>
                <strong>Tutorials:</strong>
              </p>
              <ul>
                <li>
                  <a href="/tutorial/overview-how-manage-nodejs-locally/">
                    Overview: How to Manage Node.js Locally
                  </a>
                </li>
                <li>
                  <a href="/tutorial/install-nodejs-locally-nvm/">
                    Install Node.js Locally with Node Version Manager (nvm)
                  </a>
                </li>
              </ul>
            </SectionContainer>

            <SectionContainer>
              <h2 id="env">
                Manage Application Configuration with Environment Variables in
                Node.js
              </h2>
              <p>
                Learn what environment variables are and they can be used to
                configure a Node.js application for both development and
                production. Then install the popular dotenv package with npm and
                create a <em>.env</em> file to define and document your
                variables.
              </p>
              <p>
                <strong>Tutorials:</strong>
              </p>
              <ul>
                <li>
                  <a href="/tutorial/overview-environmental-variables/">
                    How to Use Environment Variables in Node.js
                  </a>
                </li>
                <li>
                  <a href="/tutorial/set-and-test-dot-env-env-file/">
                    Set up and Test a Dot Env (.env) File in Node
                  </a>
                </li>
              </ul>
            </SectionContainer>

            <SectionContainer>
              <h2 id="proxy">
                Set up API proxy middleware with Node.js Express
              </h2>
              <p>
                Learn what a proxy server is, and why you might need a proxy for
                your API. Then build one using the popular Node.js Express
                server.
              </p>
              <p>
                <strong>Tutorials:</strong>
              </p>
              <ul>
                <li>
                  <a href="tutorial/what-api-proxy/">
                    What Is an API Proxy?
                  </a>
                </li>
                <li>
                  <a href="/tutorial/what-express-nodejs-framework/">
                    What Is the Express Node.js Framework?
                  </a>
                </li>
                <li>
                  <a href="/tutorial/how-add-route-express-server-nodejs/">
                    How to Add a Route to an Express Server in Node.js
                  </a>
                </li>
                <li>
                  <a href="/tutorial/express-middleware-nodejs/">
                    Express Middleware in Node.js
                  </a>
                </li>
                <li>
                  <a href="/tutorial/use-express-create-api-proxy-server-nodejs/">
                    Use Express to Create an API Proxy Server in Node.js
                  </a>
                </li>
                <li>
                  <a href="/tutorial/how-set-expressjs-server-nodejs/">
                    How to Set up an Express.js Server in Node.js
                  </a>
                </li>
                <li>
                  <a href="/tutorial/set-routes-your-api-nodejs/">
                    Set up Routes for Your API in Node.js
                  </a>
                </li>
                <li>
                  <a href="/tutorial/optimize-express-server-nodejs/">
                    Optimize an Express Server in Node.js
                  </a>
                </li>
                <li>
                  <a href="/tutorial/add-compression-express-nodejs/">
                    Add Compression to Express in Node.js
                  </a>
                </li>
                <li>
                  <a href="/tutorial/add-response-caching-nodejs-express-server/">
                    Add Response Caching to a Node.js Express Server
                  </a>
                </li>
              </ul>
            </SectionContainer>

            <SectionContainer>
              <h2 id="express-authentication">
                User Authentication in Node.js Applications
              </h2>
              <p>
                Authentication, and authorization, allow us to know more about
                the individuals who are interacting with our applications. Who
                are they, why are they here, and perhaps most importantly what
                are they allowed to do? This set of tutorials explores the
                fundamentals of building secure Express applications, handling
                sensitive user input, and communicating authentication status
                via sessions, cookies, and JSON Web Tokens between a Node.js
                back-end and a front-end application of your choice.
              </p>
              <p>
                <strong>Tutorials:</strong>
              </p>
              <ul>
                <li>
                  <a href="/tutorial/what-difference-between-authorization-and-authentication/">
                    What Is the Difference Between Authorization and
                    Authentication?
                  </a>
                </li>
                <li>
                  <a href="/tutorial/what-are-form-validation-and-sanitization/">
                    What Are Form Validation and Sanitization?
                  </a>
                </li>
                <li>
                  <a href="/tutorial/process-user-login-form-expressjs/">
                    Process User Login Form with ExpressJS
                  </a>
                </li>
                <li>
                  <a href="/tutorial/how-validate-and-sanitize-expressjs-form/">
                    How to Validate and Sanitize an ExpressJS Form
                  </a>
                </li>
                <li>
                  <a href="/tutorial/what-difference-between-sessions-and-json-web-tokens-jwt-authentication/">
                    What Is the Difference Between Sessions and JSON Web Tokens
                    (JWT) Authentication?
                  </a>
                </li>
                <li>
                  <a href="/tutorial/set-expressjs-session-authentication-node-applications/">
                    Set Up ExpressJS Session Authentication for Node
                    Applications
                  </a>
                </li>
                <li>
                  <a href="/tutorial/authenticate-users-node-expressjs-and-passportjs/">
                    Authenticate Users with Node ExpressJS and Passport.js{' '}
                  </a>
                </li>
              </ul>
            </SectionContainer>

            <SectionContainer>
              <h2 id="event-loop">Explore Node's Event Loop</h2>
              <p>
                The event loop is a fundamental concept of Node.js. It opens the
                door to understanding Node’s asynchronous processes and
                non-blocking I/O. It outlines the mechanisms that make Node a
                successful, powerful, and popular modern framework. These
                tutorials are useful for Node.js developers who want a deeper
                understanding of what's happening under the hood of every
                application, and those who want to take full control of every
                step of its running cycle.
              </p>
              <p>
                <strong>Tutorials:</strong>
              </p>
              <ul>
                <li>
                  <a href="/tutorial/how-event-loop-works-nodejs/">
                    How the Event Loop Works in Node.js
                  </a>
                </li>
                <li>
                  <a href="/tutorial/explore-timers-phase-nodes-event-loop/">
                    Explore the Timers Phase of Node's Event Loop
                  </a>
                </li>
                <li>
                  <a href="/tutorial/explore-io-callbacks-phase-nodejs-event-loop/">
                    Explore the I/O Callbacks Phase of the Node.js Event Loop
                  </a>
                </li>
                <li>
                  <a href="/tutorial/explore-immediate-callbacks-phase-nodes-event-loop/">
                    Explore the Immediate Callbacks Phase of Node's Event Loop
                  </a>
                </li>
              </ul>
            </SectionContainer>

            <strong>
              <Link to="/collection/data-brokering-nodejs/">
                See the complete list of tutorials &rarr;
              </Link>
            </strong>
          </Grid.Column>
        </Grid.Row>

        {!userAuthenticated && (
          <Grid.Row className="cta2">
            <Grid.Column computer={7} tablet={8} mobile={12} textAlign="center">
              <DrupalLoginButton
                title={
                  <>
                    <Icon name="right arrow" />
                    Join now
                  </>
                }
                useRegistrationLink
                classes="ui icon secondary huge left labeled button"
              />
              <p>Join now and get instant access to all our tutorials.</p>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>

      <Grid columns={12} className="instructors">
        <Grid.Row>
          <Grid.Column
            computer={4}
            tablet={4}
            verticalAlign="bottom"
            className="instructors--photo"
          >
            <Img fluid={data.JonPhoto.childImageSharp.fluid} alt="Jon Church" />
          </Grid.Column>
          <Grid.Column
            computer={5}
            tablet={6}
            verticalAlign="middle"
            className="instructors--details"
          >
            <Header as="h3">Jon says "Hi!"</Header>
            <p>
              Meet <strong>Jon Church</strong>, your Node.js teacher. He writes,
              researches, codes, and records courses that teach people the
              infinite possibilities of Node.js. He's also an enthusiastic
              supporter and contributor to the{' '}
              <a href="https://nodejs.org/en/">Node.js project</a>.
            </p>
            <p>
              <Button
                primary
                icon
                labelPosition="left"
                as={Link}
                to="/about#team"
              >
                <Icon name="right arrow" />
                Meet the rest of our team
              </Button>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid centered columns={12} container>
        <Grid.Row className="testimonials" stackable>
          <Grid.Column computer={4} tablet={12} mobile={12}>
            <blockquote>
              <Img
                fluid={data.DShawPhoto.childImageSharp.fluid}
                alt="Dan Shaw"
              />
              <div>
                <p>
                  "The Osio Labs team has done an incredible job listening to
                  the needs of the Node.js community, working closely with a
                  diverse contingent of members from across the globe, to craft
                  a training program that fits the needs of today's Node.js
                  developer."
                </p>
                <footer>
                  Dan Shaw, Global Head of Developer Relations, PayPal
                </footer>
              </div>
            </blockquote>
          </Grid.Column>
          <Grid.Column computer={4} tablet={12} mobile={12}>
            <blockquote>
              <Img
                fluid={data.RiveraPhoto.childImageSharp.fluid}
                alt="Andrew Rivera"
              />
              <div>
                <p>
                  "Your videos have been awesome! Been watching the free ones
                  off and on for the past few years. Bought a subscription
                  recently and am getting a lot out of it!"
                </p>
                <footer>Andrew Rivera, Drupal Developer</footer>
              </div>
            </blockquote>
          </Grid.Column>
          <Grid.Column computer={4} tablet={12} mobile={12}>
            <blockquote>
              <Img
                fluid={data.BikinoPhoto.childImageSharp.fluid}
                alt="Ildephonse Bikino"
              />
              <div>
                <p>
                  "Great Job @drupalizeme. U are doing great guides 4 #Drupal. I
                  personally used their site to prepare for @acquia certified
                  program and I won."
                </p>
                <footer>
                  Ildephonse Bikino, Deputy Director for ICT in Education
                  Projects
                </footer>
              </div>
            </blockquote>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="details">
          <Grid.Column computer={8} mobile={12}>
            <Header as="h2">Who is this for?</Header>

            <p>
              Data Brokering with Node.js aims to help JavaScripters who need a
              better understanding of back-end or application-layer concepts,
              those with a specific Node problem to solve, and those levelling
              up their tech skills.
            </p>

            <Header as="h2">Who is this by? </Header>

            <p>
              Hey Node is a labor of love from{' '}
              <a href="https://osiolabs.com">Osio Labs</a>. We’re Open Source
              Inside and Out, and we support and lead projects that foster and
              grow open source communities and the technology they create.
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="up-next">
          <Grid.Column computer={8} mobile={12}>
            <Header as="h2">Up next</Header>
            <p>
              Be the first to know when we add new content. Sign up for our
              newsletter.
            </p>
            <Button as={Link} to="/signup/" icon labelPosition="right">
              <Icon name="right arrow" />
              Join our mailing list
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </main>
  );
};

const IndexPageWithOauthConsumer = withDrupalOauthConsumer(IndexPage);

const IndexPageLayout = (props) => (
  <>
    <Layout withContainer={false}>
      <IndexPageWithOauthConsumer {...props} />
    </Layout>
  </>
);

export default IndexPageLayout;

export const query = graphql`
  query TutorialList {
    nodeCollection(drupal_id: { eq: "b5314379-62d3-4865-b5b6-e36f807f0d78" }) {
      drupal_id
      title
      collection_summary {
        processed
      }
      relationships {
        tutorials {
          title
          drupal_id
          summary {
            processed
          }
          path {
            alias
          }
          tutorial_access
        }
      }
    }
    allNodeTutorial {
      edges {
        node {
          drupal_id
          path {
            alias
          }
          title
          tutorial_access
          summary {
            processed
          }
        }
      }
    }
    JonPhoto: file(relativePath: { eq: "profile_photo_jon.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    BikinoPhoto: file(relativePath: { eq: "bikino.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    RiveraPhoto: file(relativePath: { eq: "rivera.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    DShawPhoto: file(relativePath: { eq: "dshaw.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    HomePageCode: file(relativePath: { eq: "sample-code.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
